import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISatisList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISatisList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  spSatisList: any[];
  spSatisList_dummy: any[];
  spSatisListCount: any[];
  spSatisListCount_dummy: any[];
  FilterParametersSaveSatisList: number;
  FilterParametersSaveSatisList_dummy: number;
  getFormNo: any[];
  getFormNo_dummy: any[];
  isComp945777Visible: "visible" | "hidden";
}

export class SatisList_ScreenBase extends React.PureComponent<ISatisList_ScreenProps, any> {
  satislist_660947_value_kuikaTableRef: React.RefObject<any>;
  satislist_394234_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "00f43241-e1e8-484c-8af8-f55474c3dc4e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625521, PropertyName: "value", Value: "ONAYLANMIŞ İŞLER" },
        { Id: 103044, PropertyName: "placeholder", Value: "Aranacak kelimeyi yazınız..." },
        { Id: 660947, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 926411, PropertyName: "title", Value: "İş No" },
        { Id: 382241, PropertyName: "value", Value: "[datafield:ticketno]" },
        { Id: 812977, PropertyName: "title", Value: "Başvuru Tarihi" },
        { Id: 679677, PropertyName: "value", Value: "[datafield:basvurutarihi]" },
        { Id: 468136, PropertyName: "title", Value: "Başvuru Türü" },
        { Id: 408011, PropertyName: "value", Value: "[datafield:basvuruturu]" },
        { Id: 885016, PropertyName: "title", Value: "Proje Türü" },
        { Id: 352743, PropertyName: "value", Value: "[datafield:projeturu]" },
        { Id: 213343, PropertyName: "title", Value: "Proje Adı" },
        { Id: 25124, PropertyName: "value", Value: "[datafield:projead]" },
        { Id: 352211, PropertyName: "title", Value: "Başvuru Sahibi" },
        { Id: 726097, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 152799, PropertyName: "title", Value: "Fatura Düzenlenecek Firma" },
        { Id: 469334, PropertyName: "value", Value: "[datafield:cariad]" },
        { Id: 243187, PropertyName: "title", Value: "İlgili Kişi" },
        { Id: 231859, PropertyName: "value", Value: "[datafield:nick]" },
        { Id: 945777, PropertyName: "title", Value: "Durum" },
        { Id: 885610, PropertyName: "value", Value: "[datafield:formstatu]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.satislist_103044_value_kuikaTextInputRef = React.createRef();
    this.satislist_660947_value_kuikaTableRef = React.createRef();
    this.satislist_394234_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      getUserInfo: [],
      getFilterParametersByUserName: [],
      SetValueOf: "",
      spSatisList: [],
      spSatisListCount: [],
      FilterParametersSaveSatisList: "",
      getFormNo: [],
      isComp945777Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("satislist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SatisListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("satislist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("satislist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SatisListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      satislist_103044_value: this.state.getFilterParametersByUserName?.at?.(0)?.satisListSearch ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  SatisListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SatisList/SatisListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.satislist_103044_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].satisListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].satisListSearch
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SatisListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SatisListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("satislist_103044_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].satisListSearch
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].satisListSearch
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SatisListPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SatisListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "satislist_103044_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_394234_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_394234_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_103044_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SatisList/SatisListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spSatisList = result?.data.spSatisList;

    stateVars.spSatisListCount = result?.data.spSatisListCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SatisListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SatisListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.satislist_103044_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].satisListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].satisListSearch
        : null
    );

    stateVars.dataSource_660947 = this.state.spSatisList;
    formVars.satislist_394234_total = ReactSystemFunctions.value(
      this,
      stateVars.spSatisListCount?.length > 0
        ? stateVars.spSatisListCount[0].recordCount
        : this.state.spSatisListCount?.length > 0
        ? this.state.spSatisListCount[0].recordCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SatisListComponent_653137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SatisList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "464411",
      ReactSystemFunctions.convertToTypeByName("", "string"),
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SatisListComponent_103044_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SatisListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_103044_value", "value", "", "", "")
        ),
        "string"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_103044_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_394234_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_394234_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Rol_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_103044_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SatisList/SatisListComponent_103044_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveSatisList = result?.data.filterParametersSaveSatisList;
    stateVars.spSatisList = result?.data.spSatisList;

    stateVars.spSatisListCount = result?.data.spSatisListCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SatisListComponent_103044_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SatisListComponent_103044_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.spSatisList;
    formVars.satislist_394234_total = ReactSystemFunctions.value(
      this,
      stateVars.spSatisListCount?.length > 0
        ? stateVars.spSatisListCount[0].recordCount
        : this.state.spSatisListCount?.length > 0
        ? this.state.spSatisListCount[0].recordCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SatisListComponent_871646_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "satislist_660947_value", "btKod"),
        "number"
      ),
      PTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "satislist_660947_value", "pftKod"),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SatisList/SatisListComponent_871646_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFormNo = result?.data.getFormNo;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SatisListComponent_871646_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SatisListComponent_871646_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmGuid",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "satislist_660947_value", "id"),
          "Guid"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmBavuruTuruID",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "satislist_660947_value", "basvuruTuruID"),
          "Guid"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "NewDisplay",
        ReactSystemFunctions.convertToTypeByName("D", "string")
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmNerdenGeldi",
        ReactSystemFunctions.convertToTypeByName("info", "string")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.SatisListComponent_871646_onClick2_,
        "SatisList",
        "BFormuBankaV2",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SatisListComponent_871646_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SatisListComponent_871646_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmGuid",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "satislist_660947_value", "id"),
          "Guid"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmBavuruTuruID",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "satislist_660947_value", "basvuruTuruID"),
          "Guid"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "NewDisplay",
        ReactSystemFunctions.convertToTypeByName("D", "string")
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmProjeTuruID",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "satislist_660947_value", "projeTuruID"),
          "Guid"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmNerdenGeldi",
        ReactSystemFunctions.convertToTypeByName("info", "string")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "SatisList",
        "BFormuTicari",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SatisListComponent_578618_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "IsTakipKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "satislist_660947_value", "id"), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "IsTakipKart",
      "prmNerdenGeldi",
      ReactSystemFunctions.convertToTypeByName("muhasebe", "string")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SatisList",
      "IsTakipKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SatisListComponent_394234_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_103044_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_394234_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_394234_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "satislist_103044_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SatisList/SatisListComponent_394234_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spSatisList = result?.data.spSatisList;

    stateVars.spSatisListCount = result?.data.spSatisListCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SatisListComponent_394234_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SatisListComponent_394234_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.spSatisList;
    formVars.satislist_394234_total = ReactSystemFunctions.value(
      this,
      stateVars.spSatisListCount?.length > 0
        ? stateVars.spSatisListCount[0].recordCount
        : this.state.spSatisListCount?.length > 0
        ? this.state.spSatisListCount[0].recordCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [464411] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SatisListPageInit();
    }
  }
}
