import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEserSahibiKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEserSahibiKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  sbCariList: any[];
  sbCariList_dummy: any[];
  sbBolumList: any[];
  sbBolumList_dummy: any[];
  getEserSahibiKartById: any[];
  getEserSahibiKartById_dummy: any[];
  getEserSahibiPayDagilimListByESId: any[];
  getEserSahibiPayDagilimListByESId_dummy: any[];
  getListCariSozlesmeBilgileri: any[];
  getListCariSozlesmeBilgileri_dummy: any[];
  getListBaglantiliMeslekBirligiByESId: any[];
  getListBaglantiliMeslekBirligiByESId_dummy: any[];
  spTarihceEserSahibi: any[];
  spTarihceEserSahibi_dummy: any[];
  setEserSahibiSave: number;
  setEserSahibiSave_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  setEserSahibiPayDagilimDelete: number;
  setEserSahibiPayDagilimDelete_dummy: number;
  DownloadFile: any;
  DownloadFile_dummy: any;
  setKartCariSozlesmeBilgileriDelete: number;
  setKartCariSozlesmeBilgileriDelete_dummy: number;
  setBaglantiliMeslekBirligiDelete: number;
  setBaglantiliMeslekBirligiDelete_dummy: number;
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  isComp281355Visible: "visible" | "hidden";
  isComp356553Visible: "visible" | "hidden";
  isComp444588Visible: "visible" | "hidden";
}

export class EserSahibiKart_ScreenBase extends React.PureComponent<IEserSahibiKart_ScreenProps, any> {
  esersahibikart_887861_value_kuikaSelectBoxRef: React.RefObject<any>;
  esersahibikart_146536_value_kuikaMultiSelectRef: React.RefObject<any>;
  esersahibikart_456888_value_kuikaTableRef: React.RefObject<any>;
  esersahibikart_447151_value_kuikaTableRef: React.RefObject<any>;
  esersahibikart_456057_value_kuikaTableRef: React.RefObject<any>;
  esersahibikart_281355_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "7e681416-90aa-49dd-9eb5-1feb5bdf45b7",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 785916, PropertyName: "value", Value: "Eser Sahibi Kartı" },
        { Id: 965590, PropertyName: "value", Value: "Müşteri Adı" },
        { Id: 894883, PropertyName: "value", Value: "Müşteri Kartına Git" },
        { Id: 40473, PropertyName: "value", Value: "Eser Sahibi Adı" },
        { Id: 973778, PropertyName: "value", Value: "Eser Sahibi Soyadı" },
        { Id: 118332, PropertyName: "value", Value: "Özel Kod" },
        { Id: 973128, PropertyName: "value", Value: "Özel Kod 2" },
        { Id: 628958, PropertyName: "value", Value: "Takma Adı" },
        { Id: 820510, PropertyName: "value", Value: "Bağlantılı Bölüm" },
        { Id: 128583, PropertyName: "value", Value: "Doğum Yılı" },
        { Id: 465804, PropertyName: "value", Value: "Ölüm Yılı" },
        { Id: 674664, PropertyName: "value", Value: "Uyruk" },
        { Id: 904704, PropertyName: "value", Value: "Bildiği Diller" },
        { Id: 987639, PropertyName: "value", Value: "Önemli Notlar" },
        { Id: 67847, PropertyName: "value", Value: "Meslek Birliği >" },
        { Id: 250412, PropertyName: "value", Value: "MUZ (Mekanik Payı)" },
        { Id: 212873, PropertyName: "value", Value: "MUZ (Temsili Payı)" },
        { Id: 861691, PropertyName: "label", Value: "Vazgeç" },
        { Id: 739020, PropertyName: "label", Value: "Kaydet" },
        { Id: 356553, PropertyName: "label", Value: "Kaydet Trigger" },
        { Id: 823422, PropertyName: "value", Value: "Yetkili Temsilcisi / Varis" },
        { Id: 456888, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 195018, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 820539, PropertyName: "value", Value: "[datafield:cariad]" },
        { Id: 740979, PropertyName: "title", Value: "Yakınlık Derecesi" },
        { Id: 363090, PropertyName: "value", Value: "[datafield:gorevi]" },
        { Id: 822894, PropertyName: "title", Value: "Eser Payı" },
        { Id: 612367, PropertyName: "value", Value: "[datafield:hakedisoran]" },
        { Id: 200012, PropertyName: "title", Value: "MUZ (Mekanik Payı)" },
        { Id: 774189, PropertyName: "value", Value: "[datafield:muzoran]" },
        { Id: 708387, PropertyName: "title", Value: "MUZ (Temsili Payı)" },
        { Id: 232750, PropertyName: "value", Value: "[datafield:muzorantemsili]" },
        { Id: 274180, PropertyName: "value", Value: "Lisans Sözleşmeleri, Fatura ve İzinler" },
        { Id: 447151, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 420236, PropertyName: "title", Value: "Alan" },
        { Id: 181895, PropertyName: "value", Value: "[datafield:alan]" },
        { Id: 649852, PropertyName: "title", Value: "Tür" },
        { Id: 259120, PropertyName: "value", Value: "[datafield:sozlesmetipi]" },
        { Id: 937090, PropertyName: "title", Value: "Konu" },
        { Id: 743690, PropertyName: "value", Value: "[datafield:sozlesmekonu]" },
        { Id: 996202, PropertyName: "title", Value: "Baş.Tarihi" },
        { Id: 330589, PropertyName: "value", Value: "[datafield:bastarihi]" },
        { Id: 789060, PropertyName: "title", Value: "Bit.Tarihi" },
        { Id: 857949, PropertyName: "value", Value: "[datafield:bittarihi]" },
        { Id: 967890, PropertyName: "title", Value: "PDF" },
        { Id: 91669, PropertyName: "value", Value: "Bağlantılı Meslek Birliği (PRO)" },
        { Id: 456057, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 733025, PropertyName: "title", Value: "Adı" },
        { Id: 806362, PropertyName: "value", Value: "[datafield:meslekbirligiad]" },
        { Id: 19410, PropertyName: "title", Value: "Kayıtlı E.S. Adı" },
        { Id: 390233, PropertyName: "value", Value: "[datafield:kayitliesadi]" },
        { Id: 993655, PropertyName: "title", Value: "IPI(CAE / Name Number)" },
        { Id: 45984, PropertyName: "value", Value: "[datafield:ipi]" },
        { Id: 112177, PropertyName: "title", Value: "Koruma Bölgesi" },
        { Id: 177858, PropertyName: "value", Value: "[datafield:korumabolgesi]" },
        { Id: 404035, PropertyName: "value", Value: "Telif Geçmişini Görüntüle" },
        { Id: 281355, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 272087, PropertyName: "title", Value: "İŞ NO" },
        { Id: 760655, PropertyName: "value", Value: "[datafield:ticketno]" },
        { Id: 665266, PropertyName: "title", Value: "TARİH" },
        { Id: 339046, PropertyName: "value", Value: "[datafield:quarterdate]" },
        { Id: 280713, PropertyName: "title", Value: "ESER ADI" },
        { Id: 733958, PropertyName: "value", Value: "[datafield:stokad]" },
        { Id: 27555, PropertyName: "title", Value: "ESER SAHİBİ" },
        { Id: 160102, PropertyName: "value", Value: "[datafield:esersahibi]" },
        { Id: 704740, PropertyName: "title", Value: "LİSANS TÜRÜ" },
        { Id: 180367, PropertyName: "value", Value: "[datafield:lisansturu]" },
        { Id: 157316, PropertyName: "title", Value: "PROJE ADI" },
        { Id: 227366, PropertyName: "value", Value: "[datafield:projead]" },
        { Id: 553777, PropertyName: "title", Value: "PROJE TÜRÜ" },
        { Id: 548609, PropertyName: "value", Value: "[datafield:projeturu]" },
        { Id: 195162, PropertyName: "title", Value: "KULLANIM TÜRÜ" },
        { Id: 795682, PropertyName: "value", Value: "[datafield:kullanimturu]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.esersahibikart_887861_value_kuikaSelectBoxRef = React.createRef();
    this.esersahibikart_802808_value_kuikaTextInputRef = React.createRef();
    this.esersahibikart_173681_value_kuikaTextInputRef = React.createRef();
    this.esersahibikart_7163_value_kuikaTextInputRef = React.createRef();
    this.esersahibikart_1186_value_kuikaTextInputRef = React.createRef();
    this.esersahibikart_874267_value_kuikaTextInputRef = React.createRef();
    this.esersahibikart_146536_value_kuikaMultiSelectRef = React.createRef();
    this.esersahibikart_316562_value_kuikaTextInputRef = React.createRef();
    this.esersahibikart_756752_value_kuikaTextInputRef = React.createRef();
    this.esersahibikart_708965_value_kuikaTextInputRef = React.createRef();
    this.esersahibikart_331991_value_kuikaTextInputRef = React.createRef();
    this.esersahibikart_456888_value_kuikaTableRef = React.createRef();
    this.esersahibikart_447151_value_kuikaTableRef = React.createRef();
    this.esersahibikart_456057_value_kuikaTableRef = React.createRef();
    this.esersahibikart_281355_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GoBack: "",
      sbCariList: [],
      sbBolumList: [],
      getEserSahibiKartById: [],
      getEserSahibiPayDagilimListByESId: [],
      getListCariSozlesmeBilgileri: [],
      getListBaglantiliMeslekBirligiByESId: [],
      spTarihceEserSahibi: [],
      setEserSahibiSave: 0,
      Notify: false,
      TriggerEvent: "",
      setEserSahibiPayDagilimDelete: 0,
      DownloadFile: "",
      setKartCariSozlesmeBilgileriDelete: "",
      setBaglantiliMeslekBirligiDelete: "",
      ToggleVisibilityOf: "",
      isComp281355Visible: "hidden",
      isComp356553Visible: "hidden",
      isComp444588Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("esersahibikart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EserSahibiKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("esersahibikart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("esersahibikart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EserSahibiKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      esersahibikart_887861_value: this.state.getEserSahibiKartById?.at?.(0)?.cariID ?? undefined,
      esersahibikart_802808_value: this.state.getEserSahibiKartById?.at?.(0)?.ad ?? undefined,
      esersahibikart_173681_value: this.state.getEserSahibiKartById?.at?.(0)?.soyad ?? undefined,
      esersahibikart_7163_value: this.state.getEserSahibiKartById?.at?.(0)?.ozelKod1 ?? undefined,
      esersahibikart_1186_value: this.state.getEserSahibiKartById?.at?.(0)?.ozelKod2 ?? undefined,
      esersahibikart_874267_value: this.state.getEserSahibiKartById?.at?.(0)?.takmaAd ?? undefined,
      esersahibikart_146536_value: this.state.getEserSahibiKartById?.at?.(0)?.baglantiliBolumIDs ?? undefined,
      esersahibikart_316562_value: this.state.getEserSahibiKartById?.at?.(0)?.dogumYil ?? undefined,
      esersahibikart_756752_value: this.state.getEserSahibiKartById?.at?.(0)?.olumYil ?? undefined,
      esersahibikart_708965_value: this.state.getEserSahibiKartById?.at?.(0)?.uyruk ?? undefined,
      esersahibikart_331991_value: this.state.getEserSahibiKartById?.at?.(0)?.diller ?? undefined,
      esersahibikart_846368_value: this.state.getEserSahibiKartById?.at?.(0)?.onemliNot ?? undefined,
      esersahibikart_419041_value: this.state.getEserSahibiKartById?.at?.(0)?.muzMPayOran ?? undefined,
      esersahibikart_979658_value: this.state.getEserSahibiKartById?.at?.(0)?.muzTPayOran ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EserSahibiKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      EserSahibiID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      EserSahibiID_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      EserSahibiId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "EserSahibiKart/EserSahibiKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbCariList = result?.data.sbCariList;

    formVars.esersahibikart_887861_value =
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].cariID
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].cariID
        : null;
    formVars.esersahibikart_887861_options = stateVars.sbCariList;
    stateVars.sbBolumList = result?.data.sbBolumList;

    formVars.esersahibikart_146536_options = stateVars.sbBolumList;
    formVars.esersahibikart_146536_value =
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].baglantiliBolumIDs
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].baglantiliBolumIDs
        : null;
    stateVars.getEserSahibiKartById = result?.data.getEserSahibiKartById;
    formVars.esersahibikart_887861_value =
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].cariID
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].cariID
        : null;
    formVars.esersahibikart_887861_options = stateVars.sbCariList;
    formVars.esersahibikart_802808_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].ad
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].ad
        : null
    );
    formVars.esersahibikart_173681_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].soyad
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].soyad
        : null
    );
    formVars.esersahibikart_7163_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].ozelKod1
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].ozelKod1
        : null
    );
    formVars.esersahibikart_1186_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].ozelKod2
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].ozelKod2
        : null
    );
    formVars.esersahibikart_874267_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].takmaAd
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].takmaAd
        : null
    );
    formVars.esersahibikart_146536_options = stateVars.sbBolumList;
    formVars.esersahibikart_146536_value =
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].baglantiliBolumIDs
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].baglantiliBolumIDs
        : null;
    formVars.esersahibikart_316562_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].dogumYil
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].dogumYil
        : null
    );
    formVars.esersahibikart_756752_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].olumYil
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].olumYil
        : null
    );
    formVars.esersahibikart_708965_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].uyruk
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].uyruk
        : null
    );
    formVars.esersahibikart_331991_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].diller
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].diller
        : null
    );
    formVars.esersahibikart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].onemliNot
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].onemliNot
        : null
    );
    formVars.esersahibikart_419041_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].muzMPayOran
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].muzMPayOran
        : null
    );
    formVars.esersahibikart_979658_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].muzTPayOran
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].muzTPayOran
        : null
    );
    stateVars.getEserSahibiPayDagilimListByESId = result?.data.getEserSahibiPayDagilimListByESId;

    stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

    stateVars.getListBaglantiliMeslekBirligiByESId = result?.data.getListBaglantiliMeslekBirligiByESId;

    stateVars.spTarihceEserSahibi = result?.data.spTarihceEserSahibi;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserSahibiKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserSahibiKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.esersahibikart_887861_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].cariID
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].cariID
        : null
    );

    stateVars.dataSource_887861 = this.state.sbCariList;
    stateVars.dataSource_887861 = this.state.sbCariList;
    formVars.esersahibikart_802808_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].ad
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].ad
        : null
    );

    formVars.esersahibikart_173681_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].soyad
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].soyad
        : null
    );

    formVars.esersahibikart_7163_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].ozelKod1
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].ozelKod1
        : null
    );

    formVars.esersahibikart_1186_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].ozelKod2
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].ozelKod2
        : null
    );

    formVars.esersahibikart_874267_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].takmaAd
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].takmaAd
        : null
    );

    formVars.esersahibikart_146536_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].baglantiliBolumIDs
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].baglantiliBolumIDs
        : null
    );

    stateVars.dataSource_146536 = this.state.sbBolumList;
    stateVars.dataSource_146536 = this.state.sbBolumList;
    formVars.esersahibikart_316562_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].dogumYil
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].dogumYil
        : null
    );

    formVars.esersahibikart_756752_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].olumYil
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].olumYil
        : null
    );

    formVars.esersahibikart_708965_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].uyruk
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].uyruk
        : null
    );

    formVars.esersahibikart_331991_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].diller
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].diller
        : null
    );

    formVars.esersahibikart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].onemliNot
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].onemliNot
        : null
    );

    formVars.esersahibikart_419041_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].muzMPayOran
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].muzMPayOran
        : null
    );

    formVars.esersahibikart_979658_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserSahibiKartById?.length > 0
        ? stateVars.getEserSahibiKartById[0].muzTPayOran
        : this.state.getEserSahibiKartById?.length > 0
        ? this.state.getEserSahibiKartById[0].muzTPayOran
        : null
    );

    stateVars.dataSource_456888 = this.state.getEserSahibiPayDagilimListByESId;

    stateVars.dataSource_447151 = this.state.getListCariSozlesmeBilgileri;
    stateVars.isComp444588Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "esersahibikart_447151_value", "dosya"),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_456057 = this.state.getListBaglantiliMeslekBirligiByESId;

    stateVars.dataSource_281355 = this.state.spTarihceEserSahibi;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EserSahibiKartComponent_83067_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EserSahibiKart",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "820946",
      ReactSystemFunctions.convertToTypeByName("", "string"),
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_996082_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_730142_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MusteriKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_887861_value", "value", "sbCariList", "id", "id")
        ),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EserSahibiKart",
      "MusteriKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_894883_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MusteriKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_887861_value", "value", "sbCariList", "id", "id")
        ),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EserSahibiKart",
      "MusteriKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_861691_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_739020_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_802808_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "esersahibikart_802808_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_887861_value", "value", "sbCariList", "id", "")
        ),
        null
      ),
      message: "!",
      formName: "esersahibikart_887861_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Ad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_802808_value", "value", "", "", "")
        ),
        "string"
      ),
      BaglantiliBolumIDs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_146536_value", "value", "sbBolumList", "id", "id")
        ),
        "string"
      ),
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_887861_value", "value", "sbCariList", "id", "id")
        ),
        "Guid"
      ),
      Diller_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_331991_value", "value", "", "", "")
        ),
        "string"
      ),
      DogumYil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_316562_value", "value", "", "", "")
        ),
        "string"
      ),
      OlumYil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_756752_value", "value", "", "", "")
        ),
        "string"
      ),
      OnemliNot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_846368_value", "value", "", "", "")
        ),
        "string"
      ),
      Soyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_173681_value", "value", "", "", "")
        ),
        "string"
      ),
      TakmaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_874267_value", "value", "", "", "")
        ),
        "string"
      ),
      Uyruk_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_708965_value", "value", "", "", "")
        ),
        "string"
      ),
      MuzMPayOran_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_419041_value", "value", "", "", "")
        ),
        "number"
      ),
      MuzTPayOran_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_979658_value", "value", "", "", "")
        ),
        "number"
      ),
      OzelKod1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_7163_value", "value", "", "", "")
        ),
        "string"
      ),
      OzelKod2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_1186_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserSahibiKart/EserSahibiKartComponent_739020_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setEserSahibiSave = result?.data.setEserSahibiSave;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserSahibiKartComponent_739020_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserSahibiKartComponent_739020_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f44b1b38_4dd3_55c6_7749_1f48507e129a_notify",
        this.defaultML,
        "Kayıt Edildi!"
      ),
      ReactSystemFunctions.convertToTypeByName("success", "string"),
      ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "number"),
      null,
      null,
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_356553_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_802808_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "esersahibikart_802808_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_887861_value", "value", "sbCariList", "id", "")
        ),
        null
      ),
      message: "!",
      formName: "esersahibikart_887861_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Ad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_802808_value", "value", "", "", "")
        ),
        "string"
      ),
      BaglantiliBolumIDs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_146536_value", "value", "sbBolumList", "id", "id")
        ),
        "string"
      ),
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_887861_value", "value", "sbCariList", "id", "id")
        ),
        "Guid"
      ),
      Diller_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_331991_value", "value", "", "", "")
        ),
        "string"
      ),
      DogumYil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_316562_value", "value", "", "", "")
        ),
        "string"
      ),
      OlumYil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_756752_value", "value", "", "", "")
        ),
        "string"
      ),
      OnemliNot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_846368_value", "value", "", "", "")
        ),
        "string"
      ),
      Soyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_173681_value", "value", "", "", "")
        ),
        "string"
      ),
      TakmaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_874267_value", "value", "", "", "")
        ),
        "string"
      ),
      Uyruk_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_708965_value", "value", "", "", "")
        ),
        "string"
      ),
      MuzMPayOran_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_419041_value", "value", "", "", "")
        ),
        "number"
      ),
      MuzTPayOran_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_979658_value", "value", "", "", "")
        ),
        "number"
      ),
      OzelKod1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_7163_value", "value", "", "", "")
        ),
        "string"
      ),
      OzelKod2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "esersahibikart_1186_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserSahibiKart/EserSahibiKartComponent_356553_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setEserSahibiSave = result?.data.setEserSahibiSave;
    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EserSahibiKartComponent_174347_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.EserSahibiKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserSahibiPayDagilimKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserSahibiPayDagilimKart",
      "prmEserSahibiID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EserSahibiKart",
      "EserSahibiPayDagilimKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "198262",
      null,
      "right",
      null,
      "400",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_820539_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MusteriKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "esersahibikart_456888_value", "dagitimYapilacakKodu"),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EserSahibiKart",
      "MusteriKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_266861_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "esersahibikart_456888_value", "id"),
        "Guid"
      ),
      EserSahibiID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "936d16f8_a04e_0f58_3dcc_7c4e9a6f7753_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserSahibiKart/EserSahibiKartComponent_266861_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setEserSahibiPayDagilimDelete = result?.data.setEserSahibiPayDagilimDelete;
      stateVars.getEserSahibiPayDagilimListByESId = result?.data.getEserSahibiPayDagilimListByESId;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserSahibiKartComponent_266861_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserSahibiKartComponent_266861_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_456888 = this.state.getEserSahibiPayDagilimListByESId;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_783941_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserSahibiPayDagilimKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "esersahibikart_456888_value", "id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "EserSahibiPayDagilimKart",
      "prmEserSahibiID",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "esersahibikart_456888_value", "eserSahibiID"),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EserSahibiKart",
      "EserSahibiPayDagilimKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "502693",
      null,
      "right",
      null,
      "400",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_549132_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.EserSahibiKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmCariID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmNerdenGeldi",
      ReactSystemFunctions.convertToTypeByName("cari", "string")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmIsKartTuruID",
      ReactSystemFunctions.convertToTypeByName(null, "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmMusteriID",
      ReactSystemFunctions.convertToTypeByName(null, "Guid")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EserSahibiKart",
      "CariSozlesmeler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "626262",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_444588_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.value(this, "esersahibikart_447151_value", "dosya"),
      ReactSystemFunctions.value(this, "esersahibikart_447151_value", "dosyaAd")
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_537518_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "esersahibikart_447151_value", "id"),
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "eebb64b2_641c_acca_9a53_5bacbf1cfff8_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserSahibiKart/EserSahibiKartComponent_537518_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKartCariSozlesmeBilgileriDelete = result?.data.setKartCariSozlesmeBilgileriDelete;
      stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserSahibiKartComponent_537518_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserSahibiKartComponent_537518_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_447151 = this.state.getListCariSozlesmeBilgileri;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_868262_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "esersahibikart_447151_value", "id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmCariID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmNerdenGeldi",
      ReactSystemFunctions.convertToTypeByName("cari", "string")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmIsKartTuruID",
      ReactSystemFunctions.convertToTypeByName(null, "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmMusteriID",
      ReactSystemFunctions.convertToTypeByName(null, "Guid")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EserSahibiKartComponent_868262_onClick1_,
      "EserSahibiKart",
      "CariSozlesmeler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "676743",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EserSahibiKartComponent_868262_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserSahibiKart/EserSahibiKartComponent_868262_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserSahibiKartComponent_868262_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserSahibiKartComponent_868262_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_447151 = this.state.getListCariSozlesmeBilgileri;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_447183_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.EserSahibiKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BaglantiliMeslekBirligiKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "BaglantiliMeslekBirligiKart",
      "prmEserSahibiID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EserSahibiKart",
      "BaglantiliMeslekBirligiKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "433350",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_254838_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "esersahibikart_456057_value", "id"),
        "Guid"
      ),
      EserSahibiID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "6624671e_0595_5a7e_4849_0505de0b6aa4_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserSahibiKart/EserSahibiKartComponent_254838_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setBaglantiliMeslekBirligiDelete = result?.data.setBaglantiliMeslekBirligiDelete;
      stateVars.getListBaglantiliMeslekBirligiByESId = result?.data.getListBaglantiliMeslekBirligiByESId;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserSahibiKartComponent_254838_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserSahibiKartComponent_254838_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_456057 = this.state.getListBaglantiliMeslekBirligiByESId;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_826606_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BaglantiliMeslekBirligiKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "esersahibikart_456057_value", "id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "BaglantiliMeslekBirligiKart",
      "prmEserSahibiID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EserSahibiKart",
      "BaglantiliMeslekBirligiKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "659105",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_119287_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp281355Visible", "string")
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserSahibiKartComponent_444588_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [820946, , , 198262, , 502693, 626262, 676743, 433350, 659105] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.EserSahibiKartPageInit();
    }
    if (diId == 676743) {
      isErrorOccurred = await this.EserSahibiKartComponent_868262_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
